<template>
	<v-card
		color="primary40"
		elevation="0"
		max-width="590px"
		width="100%"
		class="rounded-lg"
	>
		<v-container class="d-flex flex-row">
			<v-container class="my-n4 ml-n2">
				<div class="primary--text font-weight-medium ">
					{{
						isArrival
							? t("form.arrivalDate")
							: t("form.takeoffDate")
					}}
				</div>
				<div class="subtitle-2 mt-2">
					<v-icon class="ml-n1">event</v-icon> {{ formatDate(date) }}
				</div>
			</v-container>
			<v-container class="my-n4">
				<div class="primary--text font-weight-medium ">
					{{
						isArrival
							? t("form.arrivalTime")
							: t("form.takeoffTime")
					}}
				</div>
				<div class="subtitle-2 mt-2">
					<v-icon class="ml-n1">schedule</v-icon>
					{{ formatTime(date) }}
				</div>
			</v-container>
		</v-container>
	</v-card>
</template>

<script>
import dayjs from "dayjs";
import {
	FORMAT_TIME_EMPTY,
	FORMAT_DATE_EMPTY,
	FORMAT_DATE,
	FORMAT_TIME_A
} from "@/helpers/constants";
import { mapGetters } from "vuex";
export default {
	props: {
		date: String
	},
	computed: {
		...mapGetters("service", ["isArrival"])
	},
	methods: {
		formatDate(date) {
			if (!date) return FORMAT_DATE_EMPTY;
			return dayjs(date).format(FORMAT_DATE);
		},
		formatTime(date) {
			if (!date) return FORMAT_TIME_EMPTY;
			return dayjs(date).format(FORMAT_TIME_A);
		},

		t(key, options = {}) {
			return this.$t(`section.flight.${key}`, options);
		}
	}
};
</script>
