<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container class="d-flex flex-column align-center">
			<v-card-title
				class="secondary--text font-weight-bold flex-row justify-center"
			>
				{{ t("title") }}
			</v-card-title>
			<v-card-subtitle class="flex text-center font-weight-medium">
				{{ t("subtitle") }}
			</v-card-subtitle>

			<CardDateTime
				v-if="existAirport"
        @click.native="openForm()"
        v-ripple="{ center: true }"
				:date="checkinModel.flightDatetime"
				class="my-4 cursor-pointer"
			/>
			<v-form ref="form">
				<v-row style="width: 100%" dense class="mt-2 justify-center">
					<v-col cols="6" class="mb-n2">
						<DateField
							menu-disabled
							:disabled="inputDisable || isServiceShare"
							v-model="checkinModel.pickupDatetime"
							prepend-inner-icon="event"
							:title="t('pickupDate')"
							@focus="openForm()"
						/>
					</v-col>
					<v-col cols="6" class="mb-n2">
						<TimeField
							:disabled="inputDisable || isServiceShare"
							menu-disabled
							:rules="rulesTime"
							:messages="recommendedTime ? t('recommended') : ''"
							:placeholder="timePlaceholder"
							v-model="checkinModel.pickupDatetime"
							:title="`${t('pickupTime')}*`"
							@focus="openForm()"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-container>

		<v-container class="mb-3">
			<v-card-text
				v-if="existAirport"
				class="mx-n2 mb-1 flex text-center font-weight-bold"
			>
				{{ isArrival ? t("message") : t("messageDropoff") }}
			</v-card-text>
			<LayoutBtn
				:edition-locked="inputDisable || isServiceShare"
				:edit="$t('global.btnEdit')"
				@click-edit="openForm()"
				:disabledConfirm="!isValid"
				:confirm="$t('global.btnConfirm')"
				@click-confirm="confirm()"
			/>
		</v-container>
	</div>
</template>

<script>
import TimeField from "@/components/field/TimeField";
import DateField from "@/components/field/DateField";
import LayoutBtn from "@/layouts/LayoutBtn";
import CardDateTime from "@/components/card/CardDateTimeFlight";
import { FORMAT_TIME_EMPTY } from "@/helpers/constants";
import { mapActions, mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
export default {
	components: {
		CardDateTime,
		DateField,
		LayoutBtn,
		TimeField
	},
	data: () => ({
		timePlaceholder: FORMAT_TIME_EMPTY,
		isValid: false
	}),
	computed: {
		...mapGetters("service", [
			"isArrival",
			"getBasePath",
			"existAirport",
			"inputDisable",
			"isServiceShare"
		]),
		...mapState("service", [
			"checkinModel",
			"recommendedTime",
			"dateReference"
		]),
		diffMinute() {
			const flightDate = dayjs(this.dateReference);
			const pickupDate = dayjs(this.checkinModel.pickupDatetime);
			return pickupDate.diff(flightDate, "minute") || 0;
		},
		rulesTime: vm => {
			return vm.isServiceShare
				? []
				: !vm.isArrival
				? [
						() =>
							vm.diffMinute <= 0 ||
							vm.t("timeOfFlighAfter", {
								minute: Math.abs(vm.diffMinute)
							})
				  ]
				: [
						() =>
							vm.diffMinute >= 0 ||
							vm.t("timeOfFlighBefore", {
								minute: Math.abs(vm.diffMinute)
							})
				  ];
		}
	},
	activated() {
		this.calcularPickup();
		this.init();
	},

	watch: {
		checkinModel() {
			this.init();
		}
	},
	methods: {
		...mapActions("service", ["_updateService", "_calcPickupDateTime"]),
		init() {
			setTimeout(() => (this.isValid = this.$refs.form?.validate()), 500);
		},
		calcularPickup() {
			const disabledUpdateTime = true;
			this._calcPickupDateTime(disabledUpdateTime);
		},
		confirm() {
			if (!this.$refs.form.validate()) return;
			this._updateService({
				pickupDatetime: this.checkinModel.pickupDatetime
			}).then(() =>
				this.$router.push(this.getBasePath("/reservation/passenger"))
			);
		},
		t(key, options = {}) {
			return this.$t(`section.schedule.${key}`, options);
		},
		openForm() {
			if (this.inputDisable || this.isServiceShare)
				return (this.$root.$children[0].locked = true);
			this.$router.push(this.getBasePath("/form/schedule"));
		}
	}
};
</script>
